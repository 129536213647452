import { template as template_f218c94f120b4b33bbc2285f17e35a51 } from "@ember/template-compiler";
const FKControlMenuContainer = template_f218c94f120b4b33bbc2285f17e35a51(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
