import { template as template_94128197ed4a44c1b8c7490be3decd8e } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_94128197ed4a44c1b8c7490be3decd8e(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_94128197ed4a44c1b8c7490be3decd8e(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_94128197ed4a44c1b8c7490be3decd8e(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
