import { template as template_fd0febe0c42849a3b1d075fcc069c2fe } from "@ember/template-compiler";
const FKLabel = template_fd0febe0c42849a3b1d075fcc069c2fe(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
