import { template as template_98a132a9d55e4dc484aa7a697cff8731 } from "@ember/template-compiler";
const WelcomeHeader = template_98a132a9d55e4dc484aa7a697cff8731(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
