import { template as template_6caa49b7d7914eb89a0e025f81a174b9 } from "@ember/template-compiler";
const FKText = template_6caa49b7d7914eb89a0e025f81a174b9(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
